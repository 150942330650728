//Migrated
<template>
  <div
    class="alert alert-custom"
    :class="classes"
  >
    <div class="flex flex-wrap -mx-4">
      <div
        class="w-12/12 px-4 alert-custom-icon flex items-center"
        :class="iconClass"
      >
        <slot name="icon">
          <fa
            class="svg-inline--fa"
            :class="`fa-${iconSize}`"
            :icon="alertIcon"
          />
        </slot>
      </div>
      <div
        class="w-12/12 px-4 flex items-center alert-custom-content font-bold"
        :class="contentClasses"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { faSadTear, faCircleCheck, faTriangleExclamation, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'

export default defineNuxtComponent({
  props: {
    parentClass: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'error',
    },

    maxContentWidth: {
      type: Boolean,
      default: false,
    },

    iconSize: {
      type: String,
      default: '5x',
    },

    contentClass: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      icons: {
        error: faSadTear,
        warning: faTriangleExclamation,
        success: faCircleCheck,
        info: faCircleExclamation,
      },
    }
  },

  computed: {
    alertIcon () {
      return this.icons[this.type] || this.icons.info
    },

    alertType () {
      switch (this.type) {
        case 'error': return 'danger'
        case 'warning': return 'warning'
        case 'success': return 'success'
        case 'info': return 'info'
      }
      return 'light'
    },

    classes () {
      return [
        ...this.parentClass,
        `alert-${this.alertType}`,
      ]
    },

    iconClass () {
      return this.maxContentWidth
        ? 'md:w-2/12 xl:w-1/12 text-left'
        : 'md:w-4/12 xl:w-3/12 text-center'
    },

    contentClasses () {
      const classes = [this.contentClass]

      if (this.maxContentWidth) {
        classes.push('md:w-10/12 xl:w-11/12')
      } else {
        classes.push('md:w-8/12 xl:w-9/12')
      }

      return classes.join(' ')
    },
  },
})
</script>

<style lang="scss">
.alert-custom-icon {
  & > svg {
    max-width: 100%;
    max-height: 35px;
    height: 100%;
  }
}
</style>
